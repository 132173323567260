<template>
	<!-- 截单时间 -->
	<div class="cutTimeSet">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click="dialogVisible = true, form = {}">设置</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" v-loading="loading">
				<el-table-column label="类型" prop="type" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.type == 1 ? "当日达" : "预售(含次日达)"}}</span>
					</template>
				</el-table-column>
				<el-table-column label="截单时间" prop="time" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click="dialogVisible = true, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 设置 -->
		<el-dialog title="设置" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="截单时间">
					<el-time-picker v-model="form.time" placeholder="请选择截单时间" editable="false" value-format="HH:mm:ss"
						style="width: 100%;">
					</el-time-picker>
				</el-form-item>
				<el-form-item label="到货类型" required>
					<el-radio-group v-model="form.type">
						<el-radio :label="1">当日达</el-radio>
						<el-radio :label="2">预售(含次日达)</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				dialogVisible: false,
				form: {},
				typeList: [{
						id: 1,
						name: '当日达'
					},
					{
						id: 2,
						name: '预售(含次日达)'
					}
				]
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/cut_time/lst`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(item) {
				this.form = {
					...item
				}
				this.form.id = item.id
			},
			// 确定
			confirmFn() {
				if (!this.form.time) {
					this.$message.error('请选择截单时间');
					return
				}
				if (!this.form.type) {
					this.$message.error('请选择到货类型');
					return
				}
				let url = '/erp/v1/cut_time/save'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/cut_time/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>